module services {
    export module applicationcore {
        export class uNDGCodeService implements interfaces.applicationcore.IUNDGCodeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(searchText?: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "UNDGCode/GetDropdownList", {
                    searchText: searchText
                });
            }

        }
    }
    angular.module("app").service("uNDGCodeService", services.applicationcore.uNDGCodeService);
}